import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'

export default {
  name: 'VideoTutorials',
  components: {
    HeaderTopDashboard,
    VideoPlayer,
  },
  computed: {
    videoPlayer () {
      return this.$store.state.videoPlayer
    },
  },
  mounted () {
    if (this.$router.currentRoute.query.url !== undefined) {
      this.openVideoPlayer({
        url: this.$router.currentRoute.query.url,
        title: this.$router.currentRoute.query.title,
      })
    }
  },
  methods: {
    openVideoPlayer (videoPlayer) {
      this.$store.dispatch('videoPlayer/toggle', {
        show: true,
        url: videoPlayer.url,
        title: videoPlayer.title,
      })
    },
  },
}
